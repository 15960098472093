import React, { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import NextNProgress from "nextjs-progressbar";
import "react-toastify/dist/ReactToastify.css";
import "../public/assets/scss/app.scss";
import TapTop from "../components/common/widgets/Tap-Top";
import { getFormClient } from "../services/constants";
import { post } from "../services/axios";
import vars from "../helpers/utils/vars";
import { listUsersMessages, validateSessioin } from "../helpers/lib";
import { contents_url } from "../services/constants";
import man from "../public/assets/images/user.png";
import { userService } from "../services";

const SettingProvider = dynamic(
  () => import("../helpers/theme-setting/SettingProvider"),
  { ssr: false }
);
const AuthProvider = dynamic(() => import("../helpers/auth/AuthProvider"), {
  ssr: false,
});
const MessengerProvider = dynamic(
  () => import("../helpers/messenger/MessengerProvider"),
  { ssr: false }
);
const ToastContainer = dynamic(
  () => import("react-toastify").then((mod) => mod.ToastContainer),
  { ssr: false }
);

const { publicRuntimeConfig } = getConfig();
const apiUrl = `${publicRuntimeConfig.API_URL}`;

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [appSettingData, setAppSettingData] = useState({});

  const [authInfo, setAuthInfo] = useState({});
  const [validSessionData, setValidSessionData] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [targetPath, setTargetPath] = useState("/dashboard");

  const [user, setUser] = useState({});
  const [chatUsers, setChatUsers] = useState([]);
  const [chatContent, setChatContent] = useState([]);
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const [activeUser, setActiveUser] = useState("");
  const [newMessageCounts, setNewMessageCounts] = useState(0);

  const onAuth = useCallback((info, isAuth) => {
    setAuthInfo(info);
    setIsAuthenticated(isAuth);
  }, []);

  const onTarget = (val) => {
    setTargetPath(val);
  };

  const onUser = useCallback((newUser) => {
    const u = {
      long_id: newUser.long_id,
      _id: newUser._id,
      avatar: newUser.profilepictureISfile
        ? contents_url + newUser.profilepictureISfile
        : man,
      name: newUser.first_name + " " + newUser.last_name,
    };
    setUser(u);
  }, []);

  const onChatLoad = (val) => {
    setIsChatLoaded(val);
  };

  const onChatContent = (val) => {
    setChatContent(val);
  };

  const onNewMessageCounts = (val) => {
    setNewMessageCounts(val);
  };

  const onChatUsers = useCallback((users) => {
    let c = 0;
    if (users.length > 0) {
      users.forEach((u) => {
        if (u.newMessages) {
          c += u.newMessages.length;
        }
      });
    }
    setChatUsers(users);
    setNewMessageCounts(c);
  }, []);

  const setChatInfo = () => {
    let userStorage = localStorage.getItem("user");
    if (!userStorage) return;
    const user = JSON.parse(userStorage);
    if (!user) return;
    getChatUsers(user);
  };

  const onActiveUser = (user) => {
    setActiveUser(user);
  };

  const getChatUsers = useCallback(
    async (user) => {
      onChatLoad(false);
      console.log("---------Start to load Chat---------");

      const userId = user.long_id;
      const fromMessages = await listUsersMessages(null, userId);
      const toMessages = await listUsersMessages(userId, null);

      let chatUsers = [];

      /**
       * Get Users that the logged-in user got messages from
       */

      let fromUsers = [];
      if (fromMessages.length > 0) {
        fromMessages.forEach((m) => {
          if (
            m.to_userISbb_agrix_usersID_data &&
            m.to_userISbb_agrix_usersID_data._id &&
            m.to_userISbb_agrix_usersID_data._id === userId
          ) {
            if (
              m.userISbb_agrix_usersID_data &&
              m.userISbb_agrix_usersID_data._id
            ) {
              const fromUser = m.userISbb_agrix_usersID_data;
              const addUser = {
                long_id: fromUser._id,
                _id: fromUser.numeric_id,
                avatar: fromUser.profilepictureISfile
                  ? contents_url + fromUser.profilepictureISfile
                  : man,
                name: fromUser.first_name + " " + fromUser.last_name,
                status: "",
                time: "",
                newMessages: [],
                chatContent: [],
              };
              if (
                fromUsers.filter((u) => u.long_id === addUser.long_id)
                  .length === 0
              ) {
                fromUsers = [...fromUsers, addUser];
                if (
                  chatUsers.filter((u) => u.long_id === addUser.long_id)
                    .length === 0
                ) {
                  chatUsers = [...chatUsers, addUser];
                }
              }
            }
          }
        });
      }

      /**
       * Get Users that the logged-in user got messages from
       */

      let toUsers = [];
      if (toMessages.length > 0) {
        toMessages.forEach((m) => {
          if (
            m.userISbb_agrix_usersID_data &&
            m.userISbb_agrix_usersID_data._id &&
            m.userISbb_agrix_usersID_data._id === userId
          ) {
            if (
              m.to_userISbb_agrix_usersID_data &&
              m.to_userISbb_agrix_usersID_data._id
            ) {
              const toUser = m.to_userISbb_agrix_usersID_data;
              const addUser = {
                long_id: toUser._id,
                _id: toUser.numeric_id,
                avatar: toUser.profilepictureISfile
                  ? contents_url + toUser.profilepictureISfile
                  : man,
                name: toUser.first_name + " " + toUser.last_name,
                status: "",
                time: "",
                newMessages: [],
                chatContent: [],
              };
              if (
                toUsers.filter((u) => u.long_id === addUser.long_id).length ===
                0
              ) {
                toUsers = [...toUsers, addUser];

                if (
                  chatUsers.filter((u) => u.long_id === addUser.long_id)
                    .length === 0
                ) {
                  chatUsers = [...chatUsers, addUser];
                }
              }
            }
          }
        });
      }

      if (chatUsers.length > 0) {
        chatUsers.forEach((u) => {
          const messages1 = fromMessages.filter(
            (m) => m.userISbb_agrix_usersID_data._id === u.long_id
          );
          const messages2 = toMessages.filter(
            (m) => m.to_userISbb_agrix_usersID_data._id === u.long_id
          );
          const messagesAll = [...messages1, ...messages2];
          const newMessages = messages1.filter((m) => m.seenYN === "0");

          const orderedNewMessages = newMessages.sort(
            (a, b) => new Date(b._dateadded) - new Date(a._dateadded)
          );
          const orderedMessages = messagesAll.sort(
            (a, b) => new Date(b._dateadded) - new Date(a._dateadded)
          );

          u.status = orderedMessages[0].messageISsmallplaintextbox;
          u.time = orderedMessages[0]._dateadded;
          u.newMessages = orderedNewMessages;

          /**
           * Get ready with Chat contents
           */
          let messages = [];

          const from = {
            long_id: user.long_id,
            _id: user._id,
            avatar: user.profilepictureISfile
              ? contents_url + user.profilepictureISfile
              : man,
            name: user.first_name + " " + user.last_name,
            newMessages: [],
            status: "",
            time: "",
            chatContent: [],
          };
          const to = u;

          const sentMessages = messages2;

          if (sentMessages && sentMessages.length > 0) {
            sentMessages.forEach((m) => {
              if (m.messageISsmallplaintextbox) {
                const message = {
                  avatar: from.avatar,
                  name: from.name,
                  content: m.messageISsmallplaintextbox,
                  time: m._dateadded,
                  type: "send",
                };

                messages = [...messages, message];
              }
            });
          }

          const gotMessages = messages1;

          if (gotMessages.length > 0) {
            gotMessages.forEach((m) => {
              if (m.messageISsmallplaintextbox) {
                const message = {
                  avatar: to.avatar,
                  name: to.name,
                  content: m.messageISsmallplaintextbox,
                  time: m._dateadded,
                  type: "get",
                };

                messages = [...messages, message];
              }
            });
          }

          const newContent = messages.sort(
            (a, b) => new Date(a.time) - new Date(b.time)
          );

          u.chatContent = newContent;
        });
      }

      const newList = chatUsers.sort(
        (a, b) => new Date(b.time) - new Date(a.time)
      );

      if (newList.length > 0) {
        onChatUsers(newList);
        onActiveUser(newList[0]);
        onChatContent(newList[0].chatContent);
      } else {
        onChatUsers([]);
        onActiveUser({});
        onChatContent([]);
      }

      onChatLoad(true);
      console.log("--------END----------");
    },
    [onChatUsers]
  );

  const getNotificationData = useCallback(async () => {
    let userStorage = localStorage.getItem("user");
    if (!userStorage) return;
    const user = JSON.parse(userStorage);
    if (!user) return;
    console.log("Getting alerts...");
    let formData = getFormClient();
    formData.append("api_method", "get_alerts");
    formData.append("session_id", user.session_id);
    formData.append("user_id", user._id);

    try {
      const response = await post(apiUrl, formData);
      if (response.data.message === "SUCCESS") {
        setAppSettingData((prevData) => {
          return { ...prevData, __notifications: response.data?.alerts || [] };
        });
      }
    } catch (err) {
      console.log(err);
    }

    setChatInfo();
  }, [getChatUsers]);

  const handleRouteChangeComplete = useCallback(
    (url, { shallow }) => {
      console.log(
        `App is changed to ${url} ${
          shallow ? "with" : "without"
        } shallow routing.`
      );
      getNotificationData();
    },
    [getNotificationData]
  );

  useEffect(() => {
    const getAppSettingData = async () => {
      let formData = getFormClient();
      formData.append("api_method", "app_settings");
      formData.append("app_version", "1");
      formData.append("device_info", "1");

      try {
        const response = await post(apiUrl, formData);
        if (response.data.message === "SUCCESS") {
          setAppSettingData(response.data);
        } else if (response.data.error) {
          alert(response.data.message);
        }
      } catch (err) {
        alert(err.toString());
      }
    };

    const setAuthUserInfo = async () => {
      let isAuthStorage = localStorage.getItem("isAuthenticated");
      if (isAuthStorage === "done") {
        let userStorage = localStorage.getItem("user");
        const userData = JSON.parse(userStorage);

        const sessionData = await validateSessioin(
          userData?.long_id,
          userData?.session_id
        );
        console.log("session validate: ", sessionData);

        if (sessionData.message === "SUCCESS") {
          setValidSessionData(sessionData);
          onAuth(
            {
              ...userData,
              subs: sessionData?.subs,
              invs: sessionData?.invs,
              ad_subs: sessionData?.ad_subs,
            },
            true
          );
          onUser({
            ...userData,
            subs: sessionData?.subs,
            invs: sessionData?.invs,
            ad_subs: sessionData?.ad_subs,
          });
        } else {
          onAuth({}, false);
          localStorage.setItem("isAuthenticated", "null");
          // userService.logout();
          logout();
        }
      } else {
        console.log(
          "session validate: Not set yet as it's not aunthenticated yet!"
        );
      }
    };

    const logout = () => {
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      setAuthInfo({});
      setIsAuthenticated(false);
      setUser({});
      // Добавьте любые другие действия при выходе
      userService.logout(); // Вызов метода выхода (если он есть)
      router.push("/"); // Перенаправление на главную страницу или страницу входа
    };

    const setup = async () => {
      await getAppSettingData();
      await setAuthUserInfo();
      setIsLoading(false);
    };

    setup();

    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [handleRouteChangeComplete, onAuth, onUser]);

  useEffect(() => {
    if (isAuthenticated) getNotificationData();
  }, [isAuthenticated, getNotificationData]);

  return (
    <>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <title>
              Biggest Online Agricultural Marketplace - E-Market Platforms for
              Farmers
            </title>
          </Head>
          <NextNProgress
            color={vars.primaryColor}
            options={{ showSpinner: false }}
          />
          <div>
            <SettingProvider
              appData={appSettingData}
              updateAppData={(_data) => {
                setAppSettingData((prevData = {}) => {
                  return { ...prevData, ..._data };
                });
              }}
            >
              <MessengerProvider
                user={user}
                chatUsers={chatUsers}
                chatContent={chatContent}
                isChatLoaded={isChatLoaded}
                activeUser={activeUser}
                newMessageCounts={newMessageCounts}
                onUser={onUser}
                onChatUsers={onChatUsers}
                onChatContent={onChatContent}
                onChatLoad={onChatLoad}
                onActiveUser={onActiveUser}
                onNewMessageCounts={onNewMessageCounts}
              >
                <AuthProvider
                  user={authInfo}
                  isAuthenticated={isAuthenticated}
                  validSessionData={validSessionData}
                  onAuth={onAuth}
                  onTarget={onTarget}
                  targetPath={targetPath}
                >
                  <Component {...pageProps} />
                </AuthProvider>
              </MessengerProvider>
            </SettingProvider>
            <ToastContainer />
            <TapTop />
          </div>
        </>
      )}
    </>
  );
}
